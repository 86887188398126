import React, { Fragment, useEffect, useState } from "react";
import HomeCard from "../components/UI/home/Card";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ChecklistRtlIcon from "@mui/icons-material/ChecklistRtl";
import DescriptionIcon from "@mui/icons-material/Description";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DownloadIcon from "@mui/icons-material/Download";
import Card from "../components/UI/Card";
import { gql, useQuery } from "@apollo/client";
import RightSidebar from "../components/UI/home/RightSidebar";
import { Carousel } from "flowbite-react";
import dashboard from "../assets/illustrations/dashboard.svg";
import error from "../assets/illustrations/error.svg";
import { Link } from "react-router-dom";
import { useProject } from "../store/project-context";
import TimeCounter from "../components/UI/TimeCounter";
import IconButton from "../components/UI/IconButton";
import NoProject from "../components/UI/NoProject";
import ApexChart from "react-apexcharts";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import Modal from "../components/UI/Modal";
import CardSkeleton from "../components/UI/skeleton/RectangleSkeleton";
import Image from "../components/UI/Image";
import constructionSmall from "../assets/images/construction-small.jpg";
import { VisibilityOff } from "@mui/icons-material";
// import { Warning } from "@mui/icons-material";

const GET_MILESTONES = gql`
  query MyQuery($projectId: uuid!) {
    projects_milestones(
      order_by: { created_at: desc }
      where: { projectId: { _eq: $projectId } }
    ) {
      id
      endDate
      name
      startDate
      completed_at
      projectId
      milestone_equipments {
        id
        quantity
        unitPrice
        noOfHours
        type
        equipment {
          id
          name
          unit
        }
      }
      milestone_materials {
        id
        quantity
        unitPrice
        material {
          id
          name
          unit
        }
      }
      milestone_labors {
        id
        quantity
        unitPrice
        noOfHours
        labor {
          id
          type
        }
      }
      checklists {
        id
        title
        isChecked
      }
      quantifiable_tasks {
        name
        quantity
        unit
        unitRate
        id
      }
      reports(where: { inactive: { _neq: true } }) {
        checklist
        equipments
        labor
        materials
        tasks
      }
    }
  }
`;
const COUNT_IDLE_DAYS = gql`
  query MyQuery($projectId: uuid!) {
    projects_report_aggregate(
      where: { projectId: { _eq: $projectId }, inactive: { _eq: true } }
    ) {
      aggregate {
        count
      }
    }
  }
`;
const HomePage = () => {
  const { currentProject } = useProject();
  const [totalBudget, setTotalBudget] = useState(0);
  const [remainingBudget, setRemainingBudget] = useState(0);
  const [completed, setCompleted] = useState(0);
  const [chartData, setChartData] = useState(null);
  const [currentPhases, setCurrentPhases] = useState([]);
  const [overallCompleted, setOverallCompleted] = useState(0);
  // const [milestones, setMilestones] = useState([]);
  const [rangeChartData, setRangeChartData] = useState(null);
  const [chartExpanded, setChartExpanded] = useState(false);
  const [changeWidth, setChangeWidth] = useState(false);
  const [currentCapitalIsHidden, setCurrentCapitalIsHidden] = useState(true);
  const {
    data,
    loading: getMilestonesLoading,
    error: getMilestonesError,
    refetch: refetchMilestones,
  } = useQuery(GET_MILESTONES, {
    variables: { projectId: currentProject?.id },
  });
  const { data: idleDays, refetch: refetchIdleDays } = useQuery(
    COUNT_IDLE_DAYS,
    {
      variables: { projectId: currentProject?.id },
    }
  );
  useEffect(() => {
    let overallCompleted = 0;
    async function fetchData() {
      if (currentProject?.id && !getMilestonesLoading) {
        refetchMilestones();
        refetchIdleDays();
        if (data?.projects_milestones) {
          let completedCount = 0;
          let totalBudgetSum = 0;
          let totalMaterialSpentSum = 0;
          let totalEquipmentSpentSum = 0;
          let totalLaborSpentSum = 0;
          // let totalTasksSpentSum = 0;
          let milestonesData = data?.projects_milestones?.map((milestone) => {
            console.log(milestone);
            // if (new Date(milestone.startDate) < new Date(milestone.endDate)) {
            let totalEquipmentCost = 0;
            milestone.milestone_equipments?.map((el) => {
              if (el.type === "RENTED") {
                totalEquipmentCost +=
                  el.quantity * el.unitPrice * (el.noOfHours || 1);
              } else if (el.type === "PURCHASED") {
                totalEquipmentCost += el.quantity * el.unitPrice;
              }
              // totalEquipmentCost +=
              //   el.quantity * el.unitPrice * (el.noOfHours || 1);
            });
            // console.log(totalEquipmentCost);
            let totalMaterialCost = 0;
            milestone.milestone_materials?.map((el) => {
              totalMaterialCost += el.quantity * el.unitPrice;
            });
            // console.log(totalMaterialCost);
            let totalLaborCost = 0;
            milestone.milestone_labors?.map((el) => {
              totalLaborCost +=
                el.quantity * el.unitPrice * (el.noOfHours || 1);
            });

            // let totalTasksCost = 0;
            // milestone.quantifiable_tasks?.map((el) => {
            //   totalTasksCost += el.quantity * el.unitRate;
            // });
            totalBudgetSum +=
              totalEquipmentCost + totalMaterialCost + totalLaborCost;
            // totalTasksCost;
            console.log(totalEquipmentCost, totalMaterialCost, totalLaborCost);

            console.log(totalBudgetSum);
            let checkedChecklistCount = 0;
            milestone.checklists?.map((el) => {
              if (el.isChecked) {
                checkedChecklistCount++;
              }
            });
            const tasks = milestone.quantifiable_tasks?.map((task) => {
              let totalQuantity = 0;
              milestone.reports?.map((el) => {
                JSON.parse(el.tasks).map((reportTask) => {
                  if (reportTask.id === task.id) {
                    totalQuantity += parseInt(reportTask.quantity);
                  }
                });
              });
              return {
                ...task,
                reportQuantity: totalQuantity,
                progress: Math.floor((totalQuantity / task.quantity) * 100),
              };
            });

            let percentageCompleted = 0;
            tasks.map((task) => {
              console.log(task.progress);
              percentageCompleted += task.progress;
            });
            if (tasks.length > 0) {
              percentageCompleted /= tasks.length;
            } else {
              percentageCompleted = 0;
            }
            if (milestone.checklists.length > 0) {
              const checklistAverage = Math.floor(
                (checkedChecklistCount / milestone.checklists.length) * 100
              );
              percentageCompleted = Math.floor(
                (percentageCompleted + checklistAverage) / 2
              );
            }
            if (percentageCompleted >= 100) {
              completedCount++;
            }

            let totalEquipmentBudgetSpent = 0;
            let totalMaterialBudgetSpent = 0;
            let totalLaborBudgetSpent = 0;
            // let totalTasksBudgetSpent = 0;
            milestone.reports?.map((rep) => {
              JSON.parse(rep.materials).map((item) => {
                if (item.totalPrice === NaN || item.quantity === NaN) return;
                totalMaterialBudgetSpent += parseFloat(item.totalPrice);
              });
              JSON.parse(rep.equipments).map((item) => {
                if (item.unitPrice === NaN || item.quantity === NaN) return;
                if (item.type === "RENTED") {
                  totalEquipmentBudgetSpent +=
                    item.unitPrice * item.totalNoOfHours;
                } else {
                  totalEquipmentBudgetSpent +=
                    item.unitPrice * parseInt(item.quantity);
                }
              });
              JSON.parse(rep.labor).map((item) => {
                if (item.quantity === NaN || item.payPerWorker === NaN) return;
                totalLaborBudgetSpent +=
                  item.payPerWorker * (item.totalNoOfHours || item.quantity);
              });
              // JSON.parse(rep.tasks).map((item) => {
              //   if (!item.unitRate || item.unitRate === NaN || item.quantity === NaN) return;
              //   totalTasksBudgetSpent += parseFloat(item.unitRate) * item.quantity;
              // });
            });
            totalEquipmentSpentSum += totalEquipmentBudgetSpent;
            totalMaterialSpentSum += totalMaterialBudgetSpent;
            totalLaborSpentSum += totalLaborBudgetSpent;
            // totalTasksSpentSum += totalTasksBudgetSpent;

            return {
              name: milestone.name,
              startDate: milestone.startDate,
              endDate: milestone.endDate,
              completedAt: milestone.completed_at || new Date().toISOString(),
              percentageCompleted,
            };
            // }
          });
          milestonesData = milestonesData.filter((data) => data !== undefined);
          const totalBudgetSpentSum =
            totalEquipmentSpentSum + totalMaterialSpentSum + totalLaborSpentSum;
          // totalTasksSpentSum;
          setTotalBudget(totalBudgetSum);
          setCompleted(completedCount);
          setRemainingBudget(totalBudgetSum - totalBudgetSpentSum);
          setChartData({
            totalEquipmentSpentSum,
            totalMaterialSpentSum,
            totalLaborSpentSum,
            // totalTasksSpentSum
          });
          let currentPhases = [];
          console.log(milestonesData);
          milestonesData.map((milestone) => {
            const today = new Date();
            console.log(milestone);
            if (
              today >= new Date(milestone.startDate) &&
              today <= new Date(milestone.endDate)
            ) {
              currentPhases.push(milestone);
            }
            overallCompleted += milestone.percentageCompleted;
          });
          console.log(milestonesData.length);
          if (milestonesData.length > 0)
            overallCompleted /= milestonesData.length;
          else overallCompleted = 0;
          overallCompleted = Math.floor(overallCompleted);
          setOverallCompleted(overallCompleted);
          setCurrentPhases(currentPhases);
          // setMilestones(milestonesData);

          const expectedChartData = milestonesData.map((milestone) => {
            return {
              x: milestone.name,
              y: [
                new Date(milestone.startDate).getTime(),
                new Date(milestone.endDate).getTime(),
              ],
            };
          });
          console.log(expectedChartData);
          const realityChartData = milestonesData.map((milestone) => {
            if (new Date(milestone.startDate) < new Date()) {
              let date = new Date().getTime();
              if (milestone.completedAt) {
                date = new Date(milestone.completedAt).getTime();
              }
              return {
                x: milestone.name,
                y: [new Date(milestone.startDate).getTime(), date],
              };
            }
            return { x: "", y: [new Date().getTime(), new Date().getTime()] };
          });
          console.log(realityChartData);
          const rangeChartData = {
            series: [
              {
                name: "Expected",
                data: expectedChartData,
              },
              {
                name: "Reality",
                data: realityChartData,
              },
            ],
            options: {
              chart: {
                type: "rangeBar",
                zoom: {
                  enabled: false,
                },
                toolbar: {
                  show: false,
                },
              },
              plotOptions: {
                bar: {
                  horizontal: true,
                },
              },
              colors: ["#c03546", "#FFAB05"],
              xaxis: {
                type: "datetime",
                labels: {
                  datetimeFormatter: {
                    hour: "dd.MM\nHH:mm",
                  },
                },
              },
            },
          };
          setRangeChartData(rangeChartData);
        }
      } else {
        setRangeChartData(null);
      }
    }
    fetchData();
  }, [currentProject?.id, data?.projects_milestones]);

  const doughnutChart = {
    series: [
      chartData?.totalEquipmentSpentSum,
      chartData?.totalMaterialSpentSum,
      chartData?.totalLaborSpentSum,
      // chartData?.totalTasksSpentSum,
    ],
    options: {
      chart: {
        type: "donut",
        foreColor: "#ffffff",
      },
      colors: ["#c03546", "#FF6B45", "#FFAB05", "#17b978"],
      plotOptions: {
        pie: {
          expandOnClick: true,
          donut: {
            labels: {
              show: true,
              // total: {
              //   show: true
              // },
              name: {
                show: true,
                color: "#ffffff",
                formatter: () => "Capital used",
              },
              value: {
                show: true,
                // offsetY: -8,
                color: "#ffffff",
                formatter: () =>
                  (
                    ((totalBudget - remainingBudget) / totalBudget) *
                    100
                  ).toFixed(2) + "%",
              },
              dataLabels: {
                enabled: true, // Set this option to true
                style: {
                  fontSize: "16px", // Adjust font size as needed
                  fontWeight: "bold",
                  colors: ["#000000"], // Set color of the data label
                },
              },
            },
          },
        },
      },
      stroke: {
        show: false,
        width: 0,
      },
      labels: ["Equipment", "Materials", "Labor"], //, "Tasks"
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };
  useEffect(() => {
    if (getMilestonesLoading) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  }, [getMilestonesLoading]);
  if (getMilestonesLoading) {
    return (
      <div className="flex justify-center items-center h-[80vh]">
        Loading...
      </div>
    );
  } else if (currentProject) {
    return (
      <div className="flex justify-between w-full">
        {!getMilestonesError ? (
          <div
            className={`grid grid-cols-4 relative gap-y-6 gap-2 w-[inherit] pl-3 pb-3 ${
              currentProject.milestones_aggregate.aggregate.count > 0
                ? ""
                : "h-[60vh]"
            }`}
          >
            <HomeCard
              icon={<AccessTimeIcon className="text-lg" />}
              title="Time left"
              bottom={
                <TimeCounter className="mb-4" date={currentProject?.endDate} />
              }
              className="col-start-1 col-span-1"
            />
            <HomeCard
              icon={<AttachMoneyIcon className="text-lg" />}
              title="Current Capital"
              content={
                <>
                  <button
                    className="absolute top-2 right-2 text-primary"
                    onClick={() => {
                      setCurrentCapitalIsHidden(!currentCapitalIsHidden);
                    }}
                  >
                    {currentCapitalIsHidden ? (
                      <VisibilityIcon className="" />
                    ) : (
                      <VisibilityOff className="" />
                    )}
                  </button>
                  <span className="mb-4">
                    <span className="w-full block text-center">
                      <span className="mr-1">ETB</span>
                      <span className="text-xl font-medium">
                        {!currentCapitalIsHidden
                          ? remainingBudget.toLocaleString()
                          : "-"}
                      </span>
                    </span>
                    <span className="block text-primary font-medium text-xs mt-5">
                      Total capital:
                      <span className="font-bold ml-1">
                        {!currentCapitalIsHidden
                          ? totalBudget.toLocaleString()
                          : "-"}
                      </span>
                    </span>
                  </span>
                </>
              }
              className="col-start-2 col-span-1 relative"
            />
            <HomeCard
              icon={<ChecklistRtlIcon className="text-lg" />}
              title={`Milestones (${currentProject?.milestones_aggregate?.aggregate?.count})`}
              content={
                <span className="flex flex-col items-center justify-center">
                  <span className="text-3xl font-medium">{completed}</span>
                  <span className="text-xs">completed</span>
                </span>
              }
              className="col-start-3 col-span-1"
            />
            <HomeCard
              icon={<DescriptionIcon className="text-lg" />}
              title="Today's report"
              content={
                <span className="w-full flex justify-evenly items-center">
                  <Link to="/report" className="flex flex-col items-center">
                    <IconButton type="rounded" className="border-none">
                      <VisibilityIcon className="" />
                    </IconButton>
                    <span className="text-xs">View</span>
                  </Link>
                  {/* <span className="flex flex-col items-center">
                    <IconButton type="rounded" className="border-none">
                      <DownloadIcon className="" />
                    </IconButton>
                    <span className="text-xs">Download</span>
                  </span> */}
                </span>
              }
              className="col-start-4 col-span-1"
            />
            {/* <span>{JSON.stringify(rangeChartData)}</span> */}
            {currentProject.milestones_aggregate.aggregate.count > 0 ||
            currentProject?.images.length > 0 ? (
              <>
                {currentProject.milestones_aggregate.aggregate.count > 0 && (
                  <>
                    {rangeChartData && chartData ? (
                      <>
                        <Card className="flex justify-start relative col-start-1 col-span-2 h-[200px]">
                          <IconButton
                            onClick={() => {
                              setChartExpanded(true);
                              setTimeout(() => {
                                setChangeWidth(true);
                              }, 1000);
                              setChangeWidth(false);
                            }}
                            type="rounded"
                            className="border-none absolute top-1 right-1"
                          >
                            <OpenInFullIcon className="!w-4 !h-4" />
                          </IconButton>
                          {rangeChartData.series.length > 0 && (
                            <ApexChart
                              options={rangeChartData.options}
                              series={rangeChartData.series}
                              type="rangeBar"
                              height={180}
                            />
                          )}
                        </Card>
                        <div
                          // style={{
                          //   backgroundImage: `url(${constructionSmall})`,
                          //   backgroundRepeat: "no-repeat",
                          //   backgroundSize: "cover",
                          // }}
                          className="col-start-3 col-span-2 h-[200px] "
                        >
                          <Card
                            // style={{ backdropFilter: `blur(5px)` }}
                            // className={`h-full w-full bg-slate-100 bg-opacity-80 `}
                            className={`h-full w-full !bg-primary/80`}
                          >
                            {!chartData ||
                            (doughnutChart.series[0] === 0 &&
                              doughnutChart.series[1] === 0 &&
                              doughnutChart.series[2] === 0) ? (
                              <p className="text-center mt-16 text-white font-medium">
                                No budget data
                              </p>
                            ) : (
                              <ApexChart
                                options={doughnutChart.options}
                                series={doughnutChart.series}
                                type="donut"
                                height={180}
                              />
                            )}
                          </Card>
                        </div>
                      </>
                    ) : (
                      <>
                        <CardSkeleton
                          width="w-full"
                          innerHeight="h-full"
                          className="block col-start-1 col-span-2 h-[200px]"
                        />
                        <CardSkeleton
                          width="w-full"
                          innerHeight="h-full"
                          className="block col-start-3 col-span-2 h-[200px]"
                        />
                      </>
                    )}
                  </>
                )}
                <div className="w-full col-start-1 col-span-4 flex justify-between">
                  {currentProject?.images?.length > 0 ? (
                    <h1 className="text-sm font-medium">Current Site Images</h1>
                  ) : (
                    <span></span>
                  )}
                  {chartData && (
                    <Link
                      to="/report"
                      className="text-sm font-medium text-primary dark:text-blue-500 hover:underline"
                    >
                      See more
                    </Link>
                  )}
                </div>
                {currentProject?.images?.length > 0 ? (
                  <div className="h-[250px] col-start-1 col-span-2">
                    <Carousel className="">
                      {currentProject?.images?.map((image, index) => (
                        <img key={index} alt="Project site" src={image?.url} />
                      ))}
                    </Carousel>
                  </div>
                ) : (
                  <div className="h-[250px] col-start-1 col-span-2"></div>
                )}
                {currentProject?.images?.length > 0 && (
                  <div className="col-start-3 col-span-2 h-[250px]">
                    {/* <GridGallery images={currentProject?.images} propertyName="url" /> */}
                    <div className="grid grid-cols-2 grid-rows-2 gap-2 h-full">
                      {currentProject?.images[0] && (
                        <Image
                          className="row-span-1 col-span-1 h-full object-cover w-full rounded-lg"
                          src={currentProject?.images[0]?.url}
                          alt=""
                        />
                      )}
                      {currentProject?.images[1] && (
                        <Image
                          className="row-span-1 col-span-1 h-full object-cover w-full rounded-lg"
                          src={currentProject?.images[1]?.url}
                          alt=""
                        />
                      )}
                      {currentProject?.images[2] && (
                        <Image
                          height="row-span-full col-start-2 col-span-1 "
                          className="row-span-full h-full col-start-2 col-span-1 object-cover w-full rounded-lg"
                          src={currentProject?.images[2]?.url}
                          alt=""
                        />
                      )}
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className="h-full absolute top-32 col-span-full justify-self-center mb-5 mt-10 space-y-3">
                <img
                  className="w-96 object-cover"
                  src={dashboard}
                  alt="Material details illustration"
                />
                <p className="text-dark-gray text-sm">
                  Analytics for this project will show up here
                </p>
              </div>
            )}
          </div>
        ) : (
          <div className="flex flex-col justify-center w-full my-20 items-center">
            <img
              className="w-96 object-cover"
              src={error}
              alt="Material details illustration"
            />
            <p className="text-dark-gray mt-10 text-sm">
              {getMilestonesError?.message}
            </p>
          </div>
        )}
        {!getMilestonesError && (
          <RightSidebar
            clients={currentProject?.clients}
            overallCompleted={overallCompleted}
            laborExpenses={chartData?.totalLaborSpentSum}
            idleDays={idleDays?.projects_report_aggregate?.aggregate?.count}
            loading={getMilestonesLoading}
            milestones={currentPhases}
            className="overflow-y-auto max-h-screen"
          />
        )}
        {chartExpanded && (
          <Modal
            headerIsShown={true}
            modalHeight="h-[85%] sm:h-[97%]"
            modalWidth={`${changeWidth ? "!w-4/5" : "!w-[78%]"}`}
            isShown={true} //props.isShown
            hideModal={() => setChartExpanded(false)}
          >
            {changeWidth && rangeChartData ? (
              <ApexChart
                options={rangeChartData.options}
                series={rangeChartData.series}
                type="rangeBar"
                height={500}
              />
            ) : (
              <p className="absolute left-1/2 top-1/2 -translate-x-1/2">
                Loading...
              </p>
            )}
          </Modal>
        )}
      </div>
    );
  } else {
    return <NoProject />;
  }
};

export default HomePage;
