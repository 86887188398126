import { Tabs, Tab } from "../../components/UI/Tabs";
import milestoneIllustration from "../../assets/illustrations/milestones.svg";
import Button from "../../components/UI/Button";
import AddIcon from "@mui/icons-material/Add";
// import CardSkeleton from "../../components/UI/skeleton/CardSkeleton";
import React, { Fragment, useEffect, useRef, useState } from "react";
import Card from "../../components/UI/Card";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import moment from "moment";
import Toast from "../../components/UI/Toast";
import ModelTrainingOutlinedIcon from "@mui/icons-material/ModelTrainingOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import { useNavigate, useLocation } from "react-router-dom";
import { Warning } from "@mui/icons-material";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Gantt } from "gantt-task-react";
import "gantt-task-react/dist/index.css";
import { useProject } from "../../store/project-context";
import PhasesCard from "../../components/UI/phases/phasesCard";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import Table from "../../components/UI/Table";
import Progress from "../../components/UI/Progress";
import ListSkeleton from "../../components/UI/skeleton/ListSkeleton";
import RectangleSkeleton from "../../components/UI/skeleton/RectangleSkeleton";
import { CheckCircle, DashCircle } from "react-bootstrap-icons";
import NoProject from "../../components/UI/NoProject";
import CurrentPhase from "../../components/UI/phases/CurrentPhase";
// import { usePhase } from "../../store/phase-context";
Chart.register(CategoryScale);

const GET_MILESTONES = gql`
  query MyQuery($projectId: uuid!) {
    projects_milestones(
      order_by: { startDate: asc }
      where: { projectId: { _eq: $projectId } }
    ) {
      id
      endDate
      name
      startDate
      projectId
      completed_at
      milestone_equipments {
        id
        quantity
        unitPrice
        noOfHours
        type
        equipment {
          id
          name
          unit
        }
      }
      milestone_materials {
        id
        quantity
        unitPrice
        material {
          id
          name
          unit
        }
      }
      milestone_labors {
        id
        quantity
        unitPrice
        noOfHours
        labor {
          id
          type
        }
      }
      checklists {
        id
        title
        isChecked
      }
      quantifiable_tasks {
        name
        quantity
        unit
        id
      }
      tests {
        name
        id
        isChecked
      }
      reports(where: { inactive: { _neq: true } }) {
        checklist
        equipments
        labor
        materials
        tasks
      }
    }
  }
`;
const doughnutChartOptions = {
  maintainAspectRatio: false,
  layout: {
    // padding: {
    //     top: 15,
    //     left: 15,
    //     right: 15,
    //     bottom: 15
    // },
    // align: "right"
  },
  responsive: true,
  cutout: "80%",
  color: "gray  ",
  animations: {
    tension: {
      duration: 1000,
      easing: "linear",
      from: 1,
      to: 0,
      loop: true,
    },
  },
  plugins: {
    title: {
      display: false,
      // text: "Budget usage",
      color: "gray  ",
      font: {
        size: 18,
      },
      align: "center",
      position: "center",
    },
    legend: {
      display: true,
      position: "right",

      textDirection: "rtl",
      labels: {
        font: {
          size: 13,
          weight: "500",
          family: "Inter",
        },
        usePointStyle: true,
        fontColor: "#000",
        pointStyle: "circle",
        boxWidth: 5,
        boxHeight: 5,
        // pointStyleWidth: 5
      },
    },
  },
};
const doughnutChartLabels = ["Equipment", "Materials", "Labor"];
const PhasesPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentProject } = useProject();
  const [success, setSuccess] = useState(null);
  const [selectedMilestone, setSelectedMilestone] = useState(null);
  const [error, setError] = useState(null);
  const [milestones, setMilestones] = useState([]);
  const [currentPhases, setCurrentPhases] = useState(null);
  const cardRef = useRef();
  const tabRef = useRef();
  const {
    data,
    loading: getMilestonesLoading,
    error: getMilestonesError,
    refetch,
  } = useQuery(GET_MILESTONES, {
    variables: { projectId: currentProject?.id },
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    async function fetchData() {
      setSelectedMilestone(null);
      if (currentProject?.id && !getMilestonesLoading) {
        console.log(data?.projects_milestones);
        if (data?.projects_milestones) {
          const milestonesData = data?.projects_milestones?.map(
            (milestone, index) => {
              // if (new Date(milestone.startDate) < new Date(milestone.endDate)) {
              let totalEquipmentCost = 0;
              milestone.milestone_equipments?.map((el) => {
                if (el.type === "RENTED") {
                  totalEquipmentCost +=
                    el.quantity * el.unitPrice * el.noOfHours;
                } else if (el.type === "PURCHASED") {
                  totalEquipmentCost += el.quantity * el.unitPrice;
                }
              });
              // console.log(totalEquipmentCost);
              let totalMaterialCost = 0;
              milestone.milestone_materials?.map((el) => {
                totalMaterialCost += el.quantity * el.unitPrice;
              });
              // console.log(totalMaterialCost);
              let totalLaborCost = 0;
              milestone.milestone_labors?.map((el) => {
                totalLaborCost += el.quantity * el.unitPrice * el.noOfHours;
              });
              // console.log(totalLaborCost);
              let checkedChecklistCount = 0;
              milestone.checklists?.map((el) => {
                if (el.isChecked) {
                  checkedChecklistCount++;
                }
              });
              const tasks = milestone.quantifiable_tasks?.map((task) => {
                let totalQuantity = 0;
                milestone.reports?.map((el) => {
                  JSON.parse(el.tasks).map((reportTask) => {
                    if (reportTask.id === task.id) {
                      totalQuantity += parseInt(reportTask.quantity);
                    }
                  });
                });
                return {
                  ...task,
                  reportQuantity: totalQuantity,
                  progress: Math.floor((totalQuantity / task.quantity) * 100),
                };
              });
              let totalEquipmentBudgetSpent = 0;
              let totalMaterialBudgetSpent = 0;
              let totalLaborBudgetSpent = 0;
              // let totalTasksBudgetSpent = 0;

              let percentageCompleted = 0;
              tasks.map((task) => {
                console.log(task.progress);
                percentageCompleted += task.progress;
              });
              if (tasks.length > 0) {
                percentageCompleted /= tasks.length;
              } else {
                percentageCompleted = 0;
              }
              const checklistAverage = Math.floor(
                (checkedChecklistCount / milestone.checklists.length) * 100
              );
              if (checklistAverage > 0) {
                percentageCompleted = Math.floor(
                  (percentageCompleted + checklistAverage) / 2
                );
              }
              milestone.reports?.map((rep) => {
                console.log(JSON.parse(rep.materials));
                JSON.parse(rep.materials).map((item) => {
                  if (item.totalPrice === NaN || item.quantity === NaN) return;
                  totalMaterialBudgetSpent += parseFloat(item.totalPrice);
                });
                JSON.parse(rep.equipments).map((item) => {
                  if (item.unitPrice === NaN || item.quantity === NaN) return;
                  totalEquipmentBudgetSpent += item.unitPrice * item.quantity;
                });
                JSON.parse(rep.labor).map((item) => {
                  if (item.quantity === NaN || item.payPerWorker === NaN)
                    return;
                  totalLaborBudgetSpent += item.quantity * item.payPerWorker;
                });
                // JSON.parse(rep.tasks).map((item) => {
                //   if (!item.unitRate || item.unitRate === NaN || item.quantity === NaN) return;
                //   totalTasksBudgetSpent += parseFloat(item.unitRate) * item.quantity;
                // });
              });
              let currentStatus;
              const today = new Date();
              if (milestone.completed_at || percentageCompleted >= 100) {
                currentStatus = "COMPLETE";
              } else if (
                today > new Date(milestone.startDate) &&
                today < new Date(milestone.endDate)
              ) {
                currentStatus = "ON_TRACK";
              } else if (today < new Date(milestone.startDate)) {
                currentStatus = "TO_DO";
              } else if (today > new Date(milestone.endDate)) {
                currentStatus = "WARNING";
              }
              return {
                start: new Date(milestone.startDate),
                end: new Date(milestone.endDate),
                name: milestone.name,
                currentStatus,
                checklists: milestone.checklists,
                quantifiable_tasks: milestone.quantifiable_tasks,
                quantifiable_tasks_edited: tasks,
                equipments: milestone.milestone_equipments,
                labors: milestone.milestone_labors,
                materials: milestone.milestone_materials,
                tests: milestone.tests,
                projectId: milestone.projectId,
                milestoneId: milestone.id,
                id: "Task " + index,
                type: "task",
                reports: milestone.reports,
                percentageCompleted,
                // isDisabled: true,
                budget: totalEquipmentCost + totalMaterialCost + totalLaborCost,
                totalEquipmentCost,
                totalMaterialCost,
                totalLaborCost,
                budgetSpent:
                  totalEquipmentBudgetSpent +
                  totalMaterialBudgetSpent +
                  totalLaborBudgetSpent,
                totalEquipmentBudgetSpent,
                totalMaterialBudgetSpent,
                totalLaborBudgetSpent,
                styles: {
                  progressColor: "#4592af",
                  progressSelectedColor: "#226089",
                },
              };
              // }
            }
          );
          let currentPhases = [];
          milestonesData.map((milestone) => {
            const today = new Date();
            if (
              today >= new Date(milestone.start) &&
              today <= new Date(milestone.end)
            ) {
              currentPhases.push(milestone.name);
            }
          });
          console.log(currentPhases);
          setCurrentPhases(currentPhases);
          setMilestones(milestonesData.filter((el) => el !== undefined));
        }
      }
    }
    fetchData();
  }, [currentProject?.id, location?.state?.success, data?.projects_milestones]);

  useEffect(() => {
    if (location?.state?.success) {
      // getMilestones({
      //   variables: { projectId: currentProject?.id },
      // });
      refetch();
      setSuccess(location?.state?.success);
      window.history.replaceState({}, document.title);
    }
  }, [location?.state?.success]);
  let milestonesFiltered = [];
  milestones?.map((milestone) => {
    milestonesFiltered.push({
      id: milestone.id,
      name: milestone.name,
      startDate: moment(milestone.start).format("LL"),
      endDate: moment(milestone.end).format("LL"),
      progress: <Progress progress={milestone.percentageCompleted} />,
      budget: <span className="ml-3">{milestone.budget.toLocaleString()}</span>,
    });
  });
  const selectMilestone = (id) => {
    if (id === null) {
      setSelectedMilestone(null);
      return;
    }
    milestones.map((milestone) => {
      if (milestone.id === id) {
        setSelectedMilestone(milestone);
        return milestone;
      } else {
        return;
      }
    });
    cardRef.current && cardRef.current.scrollIntoView({ behavior: "smooth" });
    // tabRef.current.scrollIntoView({ behavior: "smooth" });
  };
  let completedCount = 0;
  milestones?.map((milestone) => {
    if (milestone.percentageCompleted >= 100) {
      completedCount++;
    }
  });
  if (currentProject) {
    return (
      <div ref={cardRef} className="m-3 relative h-full grid grid-cols-12">
        <Tabs
          tabRef={tabRef}
          className={`${selectedMilestone ? "col-span-9" : "col-span-12"}`}
          otherElements={
            <Button
              onClick={() => {
                navigate("/phases/new");
                setSelectedMilestone(null);
              }}>
              <AddIcon />
              New Milestone
            </Button>
          }
          callback={(activeTab) => {
            if (activeTab === 1) {
              setSelectedMilestone(null);
            }
          }}>
          <Tab label="Milestones">
            <div className="min-h-full pt-5">
              <div>
                {getMilestonesLoading && !getMilestonesError ? (
                  <>
                    <div className="flex justify-center space-x-6 w-full">
                      <RectangleSkeleton className="w-44 !h-52" />
                      <RectangleSkeleton className="w-44 !h-52" />
                      <RectangleSkeleton className="w-44 !h-52" />
                    </div>
                    <ListSkeleton />
                  </>
                ) : getMilestonesError ? (
                  <p className="flex justify-center items-center">
                    <Warning className="!w-4 !h-4 mr-1" />
                    {getMilestonesError?.message}
                  </p>
                ) : milestonesFiltered?.length ? (
                  <div className="flex flex-col justify-center w-full">
                    <div className="flex justify-center space-x-6 mb-5 w-full">
                      <PhasesCard
                        icon={<AutorenewIcon className="text-dark-blue" />}
                        title="Total Phases"
                        content={milestones?.length}
                        bgColor="bg-light-blue"
                      />
                      <PhasesCard
                        icon={<CheckOutlinedIcon className="text-green-500" />}
                        title="Completed"
                        content={completedCount}
                        bgColor="bg-green-100"
                      />
                      <PhasesCard
                        icon={
                          <ModelTrainingOutlinedIcon className="text-dark-orange" />
                        }
                        title="Current Phase"
                        content={
                          currentPhases && currentPhases.length ? (
                            <span className="text-2xl w-full inline-block overflow-hidden whitespace-nowrap text-ellipsis">
                              <CurrentPhase currentPhases={currentPhases} />
                            </span>
                          ) : (
                            "-"
                          )
                        }
                        tooltip={
                          currentPhases && currentPhases.length ? (
                            <CurrentPhase currentPhases={currentPhases} />
                          ) : (
                            "-"
                          )
                        }
                        bgColor="bg-light-orange"
                      />
                    </div>
                    <Table
                      title={`All Milestones(${milestones?.length || 0})`}
                      search={true}
                      searchList={milestonesFiltered}
                      searchPropertiesKey="name"
                      searchPlaceholder="Search a milestone"
                      delete={false}
                      checkbox={false}
                      // showDeleteManyConfirmation={showDeleteManyConfirmation}
                      checkboxClickHandler={selectMilestone}
                      headers={[
                        "Name",
                        "Start date",
                        "End date",
                        "Progress",
                        "Budget",
                      ]}
                      rows={milestonesFiltered}
                      errorMessage={
                        getMilestonesError && (
                          <p className="flex justify-center items-center">
                            <Warning className="!w-4 !h-4 mr-1" />
                            {getMilestonesError}
                          </p>
                        )
                      }
                    />
                  </div>
                ) : (
                  <div className="space-y-2 self-center col-start-5 col-end-9 flex flex-col justify-center items-center">
                    <img
                      className="w-72 mt-4 object-cover"
                      alt=""
                      src={milestoneIllustration}
                    />
                    <p className="text-gray-700 text-2xl mt-5">
                      No milestone found
                    </p>
                    <p className="text-dark-gray text-sm">
                      Start by creating your first milestone with in few steps
                    </p>
                  </div>
                )}
              </div>
            </div>
          </Tab>
          <Tab label="Gantt Chart">
            <div className="min-h-full pt-5">
              {getMilestonesError ? (
                <p className="flex justify-center items-center">
                  <Warning className="!w-4 !h-4 mr-1" />
                  {getMilestonesError?.message}
                </p>
              ) : getMilestonesLoading && !getMilestonesError ? (
                <p className="w-full text-center text-sm">Loading...</p>
              ) : !milestones?.length ? (
                <div className="space-y-2 self-center col-start-5 col-end-9 flex flex-col justify-center items-center">
                  <img
                    className="w-72 mt-4 object-cover"
                    alt=""
                    src={milestoneIllustration}
                  />
                  <p className="text-gray-700 text-2xl mt-5">
                    No milestone found
                  </p>
                  <p className="text-dark-gray text-sm">
                    Start by creating your first milestone with in few steps
                  </p>
                </div>
              ) : (
                <Gantt
                  name="Progress"
                  tasks={milestones}
                  fontSize="13px"
                  columnWidth={50}
                  rowHeight={25}
                  headerHeight={35}
                  barProgressColor={"#000"}
                />
              )}
            </div>
          </Tab>
        </Tabs>
        {milestones?.length > 0 && (
          <Card
            // cardRef={cardRef}
            className={`sticky vertical-scrollbar top-0 right-0 flex flex-col items-center overflow-x-hidden overflow-y-auto max-h-screen ml-3 space-y-3 transition-all duration-200 ${
              selectedMilestone
                ? "translate-x-0 col-span-3"
                : "translate-x-full !bg-transparent"
            }`}>
            {selectedMilestone ? (
              <>
                {/* <h1 className="text-start w-full text-lg font-medium">
                  Milestone details
                </h1> */}
                <ul className="w-full text-xs space-y-3 p-2">
                  <li className="flex flex-col justify-between">
                    <h3 className="text-gray-700 text-sm font-semibold">
                      {selectedMilestone?.name}
                    </h3>
                    <span className="text-xs mt-2 flex items-center text-dark-gray">
                      <span
                        className={`${
                          selectedMilestone.currentStatus === "COMPLETE"
                            ? "text-green-500 bg-green-100"
                            : "text-dark-red bg-light-red"
                        } rounded-full text-xs p-1 text-center inline-flex items-center`}>
                        <AccessAlarmIcon className="!w-4 !h-4" />
                      </span>
                      <span className="ml-2 whitespace-break-spaces">
                        <span
                          className={`mr-1 font-semibold ${
                            selectedMilestone.currentStatus === "COMPLETE"
                              ? "text-green-500"
                              : "text-dark-red"
                          }`}>
                          Due {moment(selectedMilestone?.end).calendar()}
                        </span>
                        ({moment(selectedMilestone?.end).endOf("day").fromNow()}
                        )
                      </span>
                    </span>
                  </li>
                  <hr />
                  <li className="flex justify-between">
                    <span className="text-dark-gray">Start date</span>
                    <span>
                      <span className="text-gray-700 font-semibold mr-1">
                        {moment(selectedMilestone?.start).format("L")}
                      </span>
                    </span>
                  </li>
                  <li className="flex justify-between">
                    <span className="text-dark-gray">Expected end date</span>
                    <span>
                      <span className="text-gray-700 font-semibold mr-1">
                        {moment(selectedMilestone?.end).format("L")}
                      </span>
                    </span>
                  </li>
                  <li className="flex justify-between">
                    <span className="text-dark-gray">Budget spent</span>
                    <span>
                      <span className="text-gray-700 font-semibold mr-1">
                        {selectedMilestone?.budgetSpent.toLocaleString()}
                      </span>
                    </span>
                  </li>
                  <li className="flex justify-between">
                    <span className="text-dark-gray">Budget remaining</span>
                    <span>
                      <span className="text-gray-700 font-semibold mr-1">
                        {(
                          selectedMilestone?.budget -
                          selectedMilestone?.budgetSpent
                        ).toLocaleString()}
                      </span>
                    </span>
                  </li>
                  <li className="flex justify-between">
                    <span className="text-dark-gray">Percentage completed</span>
                    <span>
                      <span className="text-gray-700 font-semibold mr-1">
                        {selectedMilestone?.percentageCompleted}%
                      </span>
                    </span>
                  </li>
                  <li className="flex justify-between">
                    <span className="text-dark-gray">Schedule</span>
                    <span>
                      <span className="text-gray-700 font-semibold mr-1">
                        <span
                          className={`${
                            selectedMilestone.currentStatus === "ON_TRACK" ||
                            selectedMilestone.currentStatus === "TO_DO"
                              ? "text-dark-orange bg-light-orange"
                              : selectedMilestone.currentStatus === "COMPLETE"
                              ? "text-green-500 bg-green-100"
                              : selectedMilestone.currentStatus === "ON_TRACK"
                              ? "text-primary bg-primary/20"
                              : selectedMilestone.currentStatus === "WARNING"
                              ? "text-dark-red bg-light-red"
                              : ""
                          } font-medium rounded-xl text-xs py-0.5 px-2 text-center inline-flex items-center`}>
                          {selectedMilestone.currentStatus === "ON_TRACK"
                            ? "On track"
                            : selectedMilestone.currentStatus === "TO_DO"
                            ? "To do"
                            : selectedMilestone.currentStatus === "COMPLETE"
                            ? "Complete"
                            : selectedMilestone.currentStatus === "WARNING"
                            ? "On warning"
                            : ""}
                        </span>
                      </span>
                    </span>
                  </li>
                  <hr />
                  <h4 className="text-gray-700 text-sm font-semibold">
                    Checklist
                  </h4>
                  <li className="flex justify-between">
                    {selectedMilestone?.checklists.length > 0 ? (
                      <span className="text-gray-700 space-y-2 font-semibold mr-1">
                        {selectedMilestone?.checklists?.map(
                          (checklistItem, index) => (
                            <span
                              className={`flex items-center ${
                                checklistItem.isChecked
                                  ? "text-primary"
                                  : "text-dark-gray"
                              }`}
                              key={index}>
                              {checklistItem.isChecked ? (
                                <CheckCircle />
                              ) : (
                                <DashCircle />
                              )}
                              <span className="ml-2">
                                {checklistItem.title}
                              </span>
                            </span>
                          )
                        )}
                      </span>
                    ) : (
                      <p className="w-full text-center text-gray-500">No checklist found</p>
                    )}
                  </li>
                  <hr />
                  <h4 className="text-gray-700 text-sm font-semibold">Tasks</h4>
                  <li className="">
                    <span className="w-full flex flex-col space-y-2">
                      {selectedMilestone?.quantifiable_tasks_edited?.map(
                        (item, index) => (
                          <span className="" key={index}>
                            <span className="text-gray-700">{item.name}</span>
                            <Progress
                              className="w-full"
                              progress={item?.progress}
                            />
                          </span>
                        )
                      )}
                    </span>
                  </li>
                  <hr />
                  <h4 className="text-gray-700 text-sm font-semibold">
                    Budget usage
                  </h4>
                  <li className="flex justify-between">
                    <Doughnut
                      width={150}
                      height={150}
                      className="h-full"
                      options={doughnutChartOptions}
                      data={{
                        labels: doughnutChartLabels,
                        datasets: [
                          {
                            label: "Budget usage",
                            data: [
                              selectedMilestone?.totalEquipmentBudgetSpent,
                              selectedMilestone?.totalMaterialBudgetSpent,
                              selectedMilestone?.totalLaborBudgetSpent,
                            ],
                            borderColor: ["#6050DC", "#FF6B45", "#FFAB05"],
                            backgroundColor: ["#6050DC", "#FF6B45", "#FFAB05"],
                          },
                        ],
                      }}
                    />
                  </li>
                  <Button
                    className="w-full shadow-sm hover:shadow-lg !bg-green-100 hover:!bg-green-200 !text-green-500 focus:!ring-0"
                    onClick={() => {
                      navigate("/phases/new", {
                        state: { editMode: true, milestone: selectedMilestone },
                      });
                    }}>
                    Edit Milestone
                  </Button>
                </ul>
              </>
            ) : (
              <Fragment>
                {/* <img
                  src={milestoneIllustration}
                  alt="Project details illustration"
                />
                <p className="text-dark-gray text-sm text-center">
                  Project details will be shown here once selected
                </p> */}
              </Fragment>
            )}
          </Card>
        )}
        <Toast
          type="error"
          show={error !== null}
          callback={() => {
            setError(null);
          }}
          message={error}
        />
        <Toast
          type="success"
          show={success !== null}
          callback={() => {
            setSuccess(null);
          }}
          message={success}
        />
      </div>
    );
  } else {
    return <NoProject />;
  }
};

export default PhasesPage;
