import moment from "moment";
import React, { useEffect, useState } from "react";
import useInput from "../../../hooks/use-input";
import Input from "../../UI/Input";
import DatePicker from "../../UI/DatePicker";
import IconButton from "../../UI/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { CheckCircle, PlusCircleFill, Check2, X } from "react-bootstrap-icons";
import { useProject } from "../../../store/project-context";
import { usePhase } from "../../../store/phase-context";
import Dropdown from "../../UI/Dropdown";
// import Checkbox from "../../UI/Checkbox";
const NameAndDate = (props) => {
  const {
    scrollToBottom,
    checklist,
    setChecklist,
    inputs,
    setSteps,
    steps,
    step,
    editMode,
    deletedItems,
    setDeletedItems,
    commonPhases,
    setCommonTasks,
  } = props;
  const [editedItem, setEditedItem] = useState(null);
  const { currentProject } = useProject();
  const { storePhaseData } = usePhase();
  // console.log(checklist);
  const {
    value: checklistItem,
    isValid: checklistItemIsValid,
    inputChangeHandler: checklistItemChangeHandler,
    inputBlurHandler: checklistItemBlurHandler,
    reset: resetChecklistItem,
  } = useInput((value) => value.trim() !== "");

  const formIsValid =
    inputs.name.isValid && inputs.startDate.isValid && inputs.endDate.isValid;
  useEffect(() => {
    if (formIsValid) {
      setSteps(
        steps.map((s, index) => {
          if (index === step - 1) {
            return { ...s, isValid: true };
          } else {
            return s;
          }
        })
      );
    } else {
      setSteps(
        steps.map((s, index) => {
          if (index === step - 1) {
            return { ...s, isValid: false };
          } else {
            return s;
          }
        })
      );
    }
  }, [formIsValid]);
  const removeItem = (index) => {
    if (editMode) {
      setDeletedItems([...deletedItems, checklist[index]?.id]);
    }
    setChecklist(checklist.filter((ch, i) => i != index));
    scrollToBottom();
  };
  const addItem = () => {
    if (checklistItem !== "") {
      const newChecklist = [
        ...checklist,
        { title: checklistItem, isChecked: false },
      ];
      setChecklist(newChecklist);
      if (!editMode) {
        storePhaseData({
          checklist: newChecklist,
        });
      }
      resetChecklistItem();
      setEditedItem(null);
      scrollToBottom();
    }
  };
  const selectItem = (item, index) => {
    if (item) {
      setEditedItem({ item, index });
      checklistItemChangeHandler(item);
      scrollToBottom();
    }
  };
  const applyItem = () => {
    const { item, index } = editedItem;
    if (item && checklistItem) {
      console.log(checklistItem);
      const updatedChecklist = checklist.map((item, i) => {
        if (index === i) {
          if (editMode) {
            return { ...item, title: checklistItem };
          } else {
            return { title: checklistItem };
          }
        } else return item;
      });
      setChecklist(updatedChecklist);
      if (!editMode) {
        storePhaseData({
          checklist: updatedChecklist,
        });
      }
      resetChecklistItem();
      setEditedItem(null);
      scrollToBottom();
    }
  };
  const startDateChangeHandler = (e) => {
    inputs.startDate.inputChangeHandler(e);
    if (!editMode) {
      storePhaseData({
        startDate: e,
      });
    }
  };
  const endDateChangeHandler = (e) => {
    inputs.endDate.inputChangeHandler(e);
    if (!editMode) {
      storePhaseData({
        endDate: e,
      });
    }
  };
  const onPhaseChange = (e) => {
    console.log(e);

    inputs.name.inputChangeHandler(e.name);
    setCommonTasks(e.common_tasks);
  };
  useEffect(() => {
    console.log("commonPhases");
    console.log(commonPhases);
    if (commonPhases?.length) {
      const task = commonPhases.find(
        (phase) => phase.name === inputs?.name?.value
      );
      setCommonTasks(task.common_tasks);
    }
  }, [commonPhases]);
  return (
    <div className="space-y-3 flex flex-col justify-between h-fit">
      <Dropdown
        selectHandler={onPhaseChange}
        // onBlur={materialBlurHandler}
        // value={commonTask}
        // error={materialIsInValid}
        label="Search for phases"
        placeholder="Eg. Excavation & Earthworks"
        items={commonPhases}
        propertyName="name"
        name="phase"
      />
      <Input
        onChange={inputs.name.inputChangeHandler}
        onBlur={inputs.name.inputBlurHandler}
        value={inputs.name.value}
        // error={inputs.name.isInvalid}
        label="Name"
        // helperText={<>Milestone name is required</>}
        placeholder="Interior and EMP"
        required={true}
        name="name"
        type="text"
      />
      <div className="flex justify-between items-center space-x-2 w-full">
        <DatePicker
          className="w-1/2"
          value={inputs.startDate.value}
          onChange={(e) => {
            startDateChangeHandler(e);
          }}
          onBlur={inputs.startDate.inputBlurHandler}
          // error={inputs.startDate.isInvalid}
          dateFormat="dd/MM/yyyy"
          id="start-date"
          minDate={new Date(currentProject.startDate)}
          maxDate={new Date(currentProject.endDate)}
          // helperText={<>Start date is required</>}
          label="Start date"
          placeholderText={moment().format("LL")}
        />
        <DatePicker
          className="w-1/2"
          value={inputs.endDate.value}
          onChange={(e) => {
            endDateChangeHandler(e);
          }}
          onBlur={inputs.endDate.inputBlurHandler}
          // error={inputs.endDate.isInvalid}
          dateFormat="dd/MM/yyyy"
          id="end-date"
          minDate={new Date(currentProject.startDate)}
          maxDate={new Date(currentProject.endDate)}
          // helperText={<>End date is required</>}
          label="End date"
          placeholderText={moment().add(1, "years").format("LL")}
        />
      </div>

      <div>
        <label
          className={`block mb-2 text-sm font-medium text-gray-600 dark:text-white`}>
          Checklist
        </label>
        <div>
          <Input
            onChange={checklistItemChangeHandler}
            onBlur={checklistItemBlurHandler}
            value={checklistItem}
            // error={nameIsInValid}
            // helperText={<>At least one checklist is required</>}
            placeholder="Add checklist"
            required={true}
            name="checklist"
            type="text"
          />
          {editedItem !== null ? (
            <span className="flex justify-between">
              <button
                disabled={!checklistItemIsValid}
                type="button"
                className="mt-2 disabled:text-secondary/70 hover:text-primary text-secondary flex items-center p-1 text-sm"
                onClick={() => applyItem()}>
                <Check2 className="mr-1 text-[15px]" />
                Update item
              </button>
              <button
                disabled={!checklistItemIsValid}
                type="button"
                className="mt-2 disabled:text-red-400 hover:text-red-500 text-red-500/80 flex items-center p-1 text-sm"
                onClick={() => {
                  setEditedItem(null);
                  resetChecklistItem();
                }}>
                <X className="mr-1 text-[15px]" />
                Cancel
              </button>
            </span>
          ) : (
            <button
              disabled={!checklistItemIsValid}
              type="button"
              className="mt-2 disabled:text-secondary/70 hover:text-primary text-secondary flex items-center p-1 text-sm"
              onClick={addItem}>
              <PlusCircleFill className="mr-1 text-[15px]" />
              Add item
            </button>
          )}
          {checklist.length !== 0 && (
            <ul className="list-disc">
              {checklist.map((ch, index) => (
                <li
                  key={index}
                  className={`flex items-center justify-between p-1 rounded-md text-gray-500 text-sm ${
                    index === editedItem?.index && "bg-gray-100 text-black"
                  }`}>
                  <span className="flex break-words w-full ">
                    <>
                      <CheckCircle className="mr-2" />
                      <span className="w-full block">{ch.title || ch}</span>
                    </>
                  </span>
                  <span className="flex">
                    <IconButton
                      className="border-none hover:bg-gray-50 text-gray-400 w-6 h-6"
                      type="rounded"
                      onClick={() => selectItem(ch.title || ch, index)}>
                      <EditIcon className="!w-4 !h-4" />
                    </IconButton>
                    <IconButton
                      className="border-none hover:bg-gray-50 text-red-500 w-6 h-6"
                      type="rounded"
                      onClick={() => removeItem(index)}>
                      <DeleteIcon className="!w-4 !h-4" />
                    </IconButton>
                  </span>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default NameAndDate;
